<template>
  <v-container align="center" justify="center">
    <v-form
      ref="checklistform"
      @submit.prevent="submitChecklist"
      lazy-validation
      v-model="valid"
    >
      <v-card class="c-bg-gray">
        <v-toolbar dark color="primary">
          <v-toolbar-title>Approver Report</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-container align="center" justify="center">
          <v-row class="mx-auto py-4">
            <v-col>
              <h1>
                <span class="title c-blue-text"> Assessment center</span>
                | <span class="title"> Bids Submitted</span>
              </h1>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="mx-auto" cols="12" md="12">
              <v-card
                elevation="2"
                class="tw-px-2 md:tw-px-6 tw-mt-4 md:tw-mt-10"
              >
                <div v-if="!loading">
                  <template v-if="bids !== null">
                    <p class="c-gray-text ml-5 pt-8 tw-text-lg">Kindly analyse the bids and <span class="c-blue-text"> authorize by clicking the authorize button</span></p>
                    <v-tabs
                      v-model="tab"
                      class="tw-my-4 md:tw-my-10"
                      active-class="tab-active"
                      background-color="primary"
                      center-active
                      color="warning"
                      grow
                      dark
                      show-arrows
                    >
                      <v-tabs-slider
                        color="warning"
                        class="tw-p-3"
                      ></v-tabs-slider>
                      <v-tab>Assessment Report</v-tab>
                      <v-tab>
                        {{ scanObject(garage, 'name') }}</v-tab
                      >
                    </v-tabs>
                    <v-tabs-items v-model="tab">
                      <v-tab-item>
                        <AssessmentReport
                          :is-garage="false"
                          :booking="{
                            biddingForm: biddingTemplate,
                            ...bidInfo,
                          }"
                        />
                      </v-tab-item>
                      <v-tab-item>
                        <garage-bid-template
                          :garageInfo="garageInfo"
                          :booking="{
                            biddingForm: biddingTemplate,
                            ...bidInfo,
                          }"
                          :isGarage="false"
                          :approving="approveLoader"
                          @authorize-bid="authorizeBooking"
                        />
                      </v-tab-item>
                    </v-tabs-items>
                  </template>
                  <div
                    v-else
                    class="
                      tw-py-4
                      md:tw-py-14
                      tw-flex tw-items-center tw-justify-center tw-flex-col
                    "
                  >
                    <img
                      :src="require('@/assets/img/assesment-center/no-car.png')"
                      class="tw-h-16 md:tw-h-28"
                      alt="no car"
                    />
                    <p
                      class="
                        tw-w-full
                        md:tw-w-1/3
                        tw-text-xs tw-p-3 tw-text-justify
                      "
                    >
                      This bid is  
                      <span class="c-blue-text tw-font-bold"
                        >currently unavailable.</span
                      >
                    </p>
                  </div>
                </div>
                <div
                  v-else
                  class="tw-w-full tw-flex tw-justify-center tw-py-16"
                >
                  <v-progress-circular
                    :size="50"
                    color="primary"
                    indeterminate
                  ></v-progress-circular>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-form>
  </v-container>
</template>

<script>
import GarageBidTemplate from '@/components/assessment-center/bids-submitted/garageBidTemplate'
import AssessmentReport from '@/components/assessment-center/bids-submitted/assessmentReport'
import { VAT } from '@/utils/const'

import axios from 'axios'
import { formatToHuman } from '@/utils/time'
import { formatCurrency } from '@/utils/helpers'
import DownloadReportsMixin from '@/mixins/downloadReportsMixin'

export default {
  name: 'BidDetails',
  components: { GarageBidTemplate, AssessmentReport },
  mixins: [DownloadReportsMixin],
  data(vm) {
    return {
      approveLoader: false,
      bids: null,
      garage: null,
      garageInfo: {},
      loading: true,
      tokenId: vm.$route.params.id,
      garageId: null,
      bookingId: vm.$route.params.bookingId,
      fullReport: vm.$route.params.fullReport || false,
      tab: null,
      biddingTemplate: null,
      bidInfo: {},
      vatRate: VAT,
      headers: [
        {
          text: 'No.',
          align: 'start',
          sortable: false,
          value: 'garage.id',
        },
        { text: 'Garage', value: 'garage.name' },
        { text: 'Date', value: 'biddingFormSubmittedAt' },
        { text: 'Bid Cost', value: 'cost' },
        { text: 'Status', value: 'biddingStatus' },
        { text: 'Action', value: 'action' },
      ],
    }
  },
  beforeMount() {
    this.getBooking()
  },
  methods: {
    authorizeBooking() {
        this.approveLoader = true
        axios
          .post(
            `/motor-assessment/api/garages/bid-authorize/${this.tokenId}`
          )
          .then((response) => {
            if (response.data.success) {
              this.approveLoader = false
              this.$store.commit('SNACKBAR', {
                state: 'success',
                message: response.data.msg,
                variant: 'success',
              })
            } else {
              this.$store.commit('SNACKBAR', {
                state: 'failed',
                message: response.data.msg,
                variant: 'error',
              })
              this.approveLoader = false
            }
          })
          .catch(() => {
            this.$store.commit('SNACKBAR', {
              state: 'failed',
              message: 'AN Error Occurred, try again',
              variant: 'error',
            })
            this.approveLoader = false
          })
      },
    getBooking() {
      axios
      .get(`/motor-assessment/api/garages/bid-approval/${this.tokenId}`)
        .then((res) => {
          if (res.data.success && res.data.data === null) {
            this.$router.push({ name: 'NotFound' })
            this.$toasted.error('Oops!...No bid Submitted', {
              duration: 3000,
            })
          }

          if (!res.data.success) {
            this.$router.push({ name: 'BidsSubmitted' })
            this.$toasted.error('Oops!...No bid Submitted', {
              duration: 3000,
            })
          }
          if(res.data.success) {
          const response = res.data.data
          this.bids = response.garageBiddings
          this.biddingTemplate = response.biddingTemplate
          this.bidInfo = {
            clientInfo: response.clientInfo,
            vehicleInfo: response.vehicleInfo,
            policyInfo: response.policyInfo,
            createdAt: response.bookingCreatedAt,
            externalAssessor: response.externalAssessor,
            bookingStatus: response.bookingStatus,
            authorizer: response.authorizer,
          }
          this.garageId = response.garageBiddings.garageId
          this.garageInfo = response.garageBiddings
        }
        this.getGarage()
          this.loading = false
        })
        .catch((err) => {
          this.$store.commit('SNACKBAR', {
              state: 'failed',
              message: `${err.message}, try again`,
              variant: 'error',
            })
        })
    },
    getGarage() {
      axios
        .get(`/motor-assessment/api/garages/${this.garageId}`)
        .then((res) => {
          if (res.data.success && res.data.data === null)
            this.$router.push({ name: 'NotFound' })
          this.garage = res.data.data
          this.garageInfo.garage={ name: this.garage.name}
           })
        .catch((err) => {
          this.$store.commit('SNACKBAR', {
              state: 'failed',
              message: `${err.message}, try again`,
              variant: 'error',
            })
        })
    },
    netTotal(item) {
      return item.reduce((result, part) => {
        return result + part.unitPrice * part.quantity
      }, 0)
    },
    vatTotal(item) {
      return item
        .filter((i) => i.vat === true)
        .reduce((res, part) => {
          return res + this.getVat(part.unitPrice * part.quantity)
        }, 0)
    },
    getVat(amount) {
      return (this.vatRate / 100) * amount
    },
  },
  filters: {
    formatCurrency,
    formatToHuman,
  },
}
</script>
